const routes = {
  '/': '',
  '/dashboard': 'Dashboard',
  '/people': 'People',
  '/people/new': 'New People',
  '/person/:id': 'Person',
  '/donations': 'Donations',
  '/donations/pending': 'Pending Donations',
  // '/wires': 'Wires',
  '/funds': 'Funds',
  '/admin': 'Admin',
  '/login': 'Login',
  '/forgot-password': 'Forgot Password',
  '/change-password': 'Change Password',
};
export default routes;
