import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import nav from './_nav';
import SidebarFooter from './../SidebarFooter/SidebarFooter';
import SidebarHeader from './../SidebarHeader/SidebarHeader';
import SidebarMinimizer from './../SidebarMinimizer/SidebarMinimizer';
import { hasAtLeastRole } from '../../utils';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
    this.hideMobile = this.hideMobile.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    event.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName, props) {
    return props.location.pathname.indexOf(routeName) > -1
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown';
  }

  hideMobile() {
    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show');
    }
  }

  render() {
    const { props } = this;
    const badge = (thisBadge) => {
      if (thisBadge) {
        const classes = classNames(thisBadge.class);
        return (
          <Badge className={classes} color={thisBadge.variant}>
            {thisBadge.text}
          </Badge>
        );
      }
      return null;
    };

    // simple wrapper for nav-title item
    const wrapper = item =>
      (item.wrapper && item.wrapper.element
        ? React.createElement(
          item.wrapper.element,
          item.wrapper.attributes,
          item.name
        )
        : item.name);

    // nav list section title
    const title = (thisTitle, key) => {
      const classes = classNames('nav-title', thisTitle.class);
      return (
        <li key={key} className={classes}>
          {wrapper(thisTitle)}{' '}
        </li>
      );
    };

    const isExternal = (url) => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    // nav link
    const navLink = (item, key, classes) => {
      const url = item.url ? item.url : '';
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink href={url} className={classes.link} active>
              <i className={classes.icon} />
              {item.name}
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            <NavLink
              to={url}
              className={classes.link}
              activeClassName={`${
                item.url === location.pathname ? 'active' : ''
              }`}
              onClick={this.hideMobile}
            >
              <i className={classes.icon} />
              {item.name}
              {badge(item.badge)}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav list divider
    const divider = (thisDivider, key) => {
      const classes = classNames('divider', thisDivider.class);
      return <li key={key} className={classes} />;
    };

    // nav label with nav link
    const navLabel = (item, key) => {
      const classes = {
        item: classNames('hidden-cn', item.class),
        link: classNames('nav-label', item.class ? item.class : ''),
        icon: classNames(
          !item.icon ? 'fa fa-circle' : item.icon,
          item.label.variant ? `text-${item.label.variant}` : '',
          item.label.class ? item.label.class : ''
        ),
      };
      return navLink(item, key, classes);
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames(
          'nav-link',
          item.variant ? `nav-link-${item.variant}` : ''
        ),
        icon: classNames(item.icon),
      };
      return navLink(item, key, classes);
    };
    // nav dropdown
    const navDropdown = (item, key) => (
      <li key={key} className={this.activeRoute(item.url, props)}>
        <a
          className="nav-link nav-dropdown-toggle"
          href="#"
          onClick={this.handleClick}
        >
          <i className={item.icon} />
          {item.name}
        </a>
        <ul className="nav-dropdown-items">{navList(item.children)}</ul>
      </li>
    );

    // nav type
    const navType = (item, idx) =>
      (item.title
        ? title(item, idx)
        : item.divider
          ? divider(item, idx)
          : item.label
            ? navLabel(item, idx)
            : item.children
              ? navDropdown(item, idx)
              : navItem(item, idx));

    const navList = items =>
      items.map((item, index) => {
        if (!item.role) return navType(item, index);
        if (hasAtLeastRole(this.props.data.me.role, item.role)) {
          return navType(item, index);
        }
        return null;
      });

    // sidebar-nav root
    return (
      <div className="sidebar">
        <SidebarHeader />
        <nav className="sidebar-nav">
          <Nav>{navList(nav.items)}</Nav>
        </nav>
        <SidebarFooter />
        <SidebarMinimizer />
      </div>
    );
  }
}

export default Sidebar;
