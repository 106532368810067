import React from 'react';
import Funds from '../Funds/Funds';

const Dashboard = () => (
  <div className="animated fadeIn">
    <Funds />
  </div>
);

export default Dashboard;
