const GRAPHQL_SERVER =
  location.hostname === 'localhost'
    ? 'http://localhost:5000'
    : 'https://coffee-server.trashmountain.com';

const BUGSNAG_API_KEY = '5561761fb35bd56036ae696b51f4cf93';

const TOKEN_NAME = '@coffee/token';

export { GRAPHQL_SERVER, TOKEN_NAME, BUGSNAG_API_KEY };
