import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import userQuery from './Me';
import Loading from './Loading';
import ChangePassword from '../views/Passwords/ChangePassword';

const PrivateRoute = ({
  component: Component, loading, me, ...rest
}) => {
  if (loading) {
    return <Loading />;
  } else if (me) {
    const location = { ...rest };
    if (location.location.pathname === '/change-password') {
      return (
        <Route
          exact
          path="/change-password"
          name="ChangePassword"
          component={ChangePassword}
          {...rest}
          locaiton={location}
        />
      );
    }
    return (
      <Route
        {...rest}
        locaiton={location}
        render={props => <Component {...props} />}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={props => (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )}
    />
  );
};

export default withApollo(graphql(userQuery, {
  options: { fetchPolicy: 'network-only' },
  props: ({ data: { loading, me } }) => ({
    loading,
    me,
  }),
})(PrivateRoute));
