export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Donations',
      url: '/donations',
      icon: 'icon-present',
      children: [
        {
          name: 'Current',
          url: '/donations',
          icon: 'icon-check',
        },
        {
          name: 'Pending',
          url: '/donations/pending',
          icon: 'icon-refresh',
        },
      ],
    },
    {
      name: 'People',
      url: '/people',
      icon: 'icon-people',
      children: [
        {
          name: 'All People',
          url: '/people',
          icon: 'fa fa-users',
        },
        {
          name: 'New People',
          url: '/people/new',
          icon: 'icon-user-follow',
        },
      ],
    },
    // {
    //   name: 'Wires',
    //   url: '/wires',
    //   icon: 'icon-paper-plane',
    //   role: 'MANAGER',
    // },
    {
      name: 'Funds',
      url: '/funds',
      icon: 'fa fa-dollar',
    },
    {
      name: 'Admin',
      url: '/admin',
      icon: 'icon-shield',
      role: 'SUPER_ADMIN',
    },
  ],
};
