import React from 'react';

import PropTypes from 'prop-types';

import { Button } from 'reactstrap';
import { getCSV } from '../utils';

const PersonsDownload = ({ persons }) => {
  const startDownload = () => {
    const newPersonsArray = persons.map(person => {
      const newPerson = {
        'Recognition Name': person.recognition_name,
        'Company Name': person.company_name,
        'First Donation Date': person.first_donation_date,
        'Addresses':
          person.addresses &&
          person.addresses.length &&
          person.addresses[0] &&
          `${person.addresses[0].street_address} ${person.addresses[0].city} ${person.addresses[0].state} ${person.addresses[0].postal_code}`,
        'Address 2':
        person.addresses &&
        person.addresses.length &&
        person.addresses[1] &&
        `${person.addresses[1].street_address} ${person.addresses[1].city} ${person.addresses[1].state} ${person.addresses[1].postal_code}`,
        'Phone Number':
          person.phone_numbers &&
          person.phone_numbers.length &&
          `${person.phone_numbers[0].phone_number}`,
        'Phone Number 2':
        person.phone_numbers &&
        person.phone_numbers.length &&
        person.phone_numbers[1] &&
        `${person.phone_numbers[1].phone_number}`,
        'Email Addresses': person.email_addresses &&
          person.email_addresses.length &&
          person.email_addresses[0].email_address,
        'Email Address 2': person.email_addresses &&
        person.email_addresses.length &&
        person.email_addresses[1] &&
        person.email_addresses[1].email_address
      };
      return newPerson;
    });

    getCSV(newPersonsArray);
  };

  return (
    <React.Fragment>
      <Button
        color="link"
        tabIndex="0"
        onClick={() => startDownload()}
        style={{ cursor: 'pointer', padding: 0 }}
      >
        Download To Excel
      </Button>
    </React.Fragment>
  );
};

PersonsDownload.propTypes = {
  persons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      recognition_name: PropTypes.string,
      company_name: PropTypes.string,
      first_donation_date: PropTypes.date,
      names: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
        })
      ),
      addresses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          street_address: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          postal_code: PropTypes.string,
        })
      ),
      phone_numbers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          phone_number: PropTypes.string,
        })
      ),
      email_addresses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          email_address: PropTypes.string,
        })
      ),
    }),
  ).isRequired,
};

export default PersonsDownload;
