import React from 'react';
import gql from 'graphql-tag';
import ReactTable from 'react-table';
import moment from 'moment';

import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';

import Loading from '../../components/Loading';
import '../../styles/table.css';
import { defaultFilterMethod } from '../../utils';

const NEW_PEOPLE = gql`
  query newPeople {
    newPeople {
      id
      recognition_name
      first_donation_date
      first_donation_amount {
        cents
      }
      email_addresses {
        email_address
      }
      phone_numbers {
        phone_number
      }
      addresses {
        street_address
        city
        state
        postal_code
        id
      }
    }
  }
`;

const columns = [
  {
    Header: 'Name',
    id: 'recognition_name',
    accessor: d => <Link to={`/person/${d.id}`}>{d.recognition_name}</Link>,
    maxWidth: 200,
    filterMethod: (filter, row) => defaultFilterMethod(filter, row._original),
  },
  {
    Header: 'Addresses',
    id: 'addresses',
    accessor: d =>
      d.addresses &&
      d.addresses[0] &&
      `${d.addresses[0].street_address} ${d.addresses[0].city}, ${
        d.addresses[0].state
      } ${d.addresses[0].postal_code}`,
  },
  {
    Header: 'Email',
    id: 'email',
    accessor: d => d.email_addresses[0].email_address,
  },
  {
    Header: 'Phone',
    id: 'phone',
    accessor: d => d.phone_numbers[0].phone_number,
    maxWidth: 140,
  },
  {
    Header: 'First Donation',
    id: 'first_donation',
    accessor: d => `$${(d.first_donation_amount.cents / 100).toFixed(2)}`,
    sortMethod: (a, b) => {
      if (Number(a.slice(1)) === Number(b.slice(1))) {
        return 0;
      }
      return Number(a.slice(1)) > Number(b.slice(1)) ? 1 : -1;
    },
    maxWidth: 110,
  },
  {
    Header: '1st Donation Date',
    id: 'first_donation_date',
    accessor: d =>
      moment(d.first_donation_date.toString()).format('YYYY-MM-DD'),
    maxWidth: 135,
  },
];

const NewPeopleTable = ({ newPeople }) => {
  const notEmpty = newPeople.filter(person => person || null);
  return (
    <div>
      <ReactTable
        data={notEmpty}
        filterable
        defaultFilterMethod={defaultFilterMethod}
        columns={columns}
        defaultPageSize={10}
        className="-striped -highlight"
        defaultSorted={[
          {
            id: 'first_donation_date',
            desc: true,
          },
        ]}
      />
      <br />
    </div>
  );
};

const NewPeople = () => (
  <Query query={NEW_PEOPLE}>
    {({ loading, error, data: { newPeople } }) => {
      if (loading) return <Loading />;
      if (error) return `Error!: ${error}`;
      return (
        <React.Fragment>
          <NewPeopleTable newPeople={newPeople} />
        </React.Fragment>
      );
    }}
  </Query>
);

export default NewPeople;
