import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'reactstrap';
import { getCSV } from '../utils';

const DownloadToExcel = ({ splits }) => {
  const startDownload = () => {
    const newSplitArray = splits.map(split => {
      const newSplit = {
        'Received on': split.received_on,
        Amount: split.amount_in_cents
          ? `$${(split.amount_in_cents / 100).toFixed(2)}`
          : 0,
        Note: split.memo,
        'Recognition Name':
          split.donation &&
          split.donation.person &&
          split.donation.person.recognition_name,
        Addresses:
          split.donation &&
          split.donation.person &&
          split.donation.person.addresses &&
          split.donation.person.addresses.length &&
          split.donation.person.addresses.map(
            (address, index) =>
              `${
                index > 0
                  ? `
          `
                  : ''
              }${address.street_address} ${address.city}, ${address.state} ${
                address.postal_code
              }`,
          ),
        'Email addresses':
          split.donation &&
          split.donation.person &&
          split.donation.person.email_addresses &&
          split.donation.person.email_addresses.length &&
          split.donation.person.email_addresses.map(
            (email, index) => `${index > 0 ? ' ' : ''}${email.email_address}`,
          ),
        'Phone numbers':
          split.donation &&
          split.donation.person &&
          split.donation.person.phone_numbers &&
          split.donation.person.phone_numbers.length &&
          split.donation.person.phone_numbers.map(
            (phone, index) => `${index > 0 ? ' ' : ''}${phone.phone_number}`,
          ),
        'Donation Method': split.donation && split.donation.donation_type_name,
        'Fund Name': split.fund && split.fund.name,
      };
      return newSplit;
    });

    getCSV(newSplitArray);
  };

  return (
    <React.Fragment>
      <Button
        color="link"
        tabIndex="0"
        onClick={() => startDownload()}
        style={{ cursor: 'pointer', padding: 0 }}
      >
        Download To Excel
      </Button>
    </React.Fragment>
  );
};

DownloadToExcel.propTypes = {
  splits: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      trial_end: PropTypes.string,
      memo: PropTypes.string,
      fund: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default DownloadToExcel;
