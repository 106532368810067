import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import 'react-table/react-table.css';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import Loading from '../../components/Loading';
import '../../styles/table.css';

const columns = [
  {
    Header: 'Name',
    id: 'name',
    accessor: d => d.name,
  },
  {
    Header: 'Total Raised',
    id: 'raised_formatted',
    accessor: d => d.raised_formatted,
  },
];

const FundsQuery = gql`
  query FundsQuery($page: Int!, $perPage: Int!) {
    funds(page: $page, perPage: $perPage) {
      id
      name
      raised_formatted
    }
  }
`;

class Funds extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 0,
    };
  }

  render() {
    return (
      <Query
        query={FundsQuery}
        variables={{
          page: 1,
          perPage: 10,
        }}
      >
        {({
          loading, error, data, fetchMore, networkStatus,
        }) => {
          if (loading) return <Loading />;
          if (error) return `Error!: ${error}`;
          if (networkStatus === 4) return 'Refetching!';
          const { funds } = data;
          const { page } = this.state;
          const { history } = this.props;
          return (
            <React.Fragment>
              <ReactTable
                data={funds}
                pages={funds.pages || 1}
                columns={columns}
                defaultPageSize={10}
                page={page}
                onPageChange={(pageIndex) => {
                  fetchMore({
                    variables: {
                      page: pageIndex,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return Object.assign({}, prev, {
                        funds: [...prev.funds, ...fetchMoreResult.funds],
                      });
                    },
                  });
                  this.setState({ page: pageIndex });
                }}
                getTrProps={(state, rowInfo) => ({
                  onClick: () => history.push(
                    `/donations?range=month&timeFrame=last&name=${
                      rowInfo.original.name
                    }`
                  ),
                })}
                className="-striped -highlight clickable-row"
              />
              <br />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(Funds);
