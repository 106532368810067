import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../../components/Loading';
import PeopleTable from '../../components/PeopleTable';

const PeopleQuery = gql`
  query PeopleQuery {
    persons {
      id
      recognition_name
      tag_list
      company_name
      first_donation_date
      names {
        id
        first_name
        last_name
      }
      addresses {
        street_address
        city
        state
        postal_code
        id
      }
      phone_numbers {
        id
        phone_number
      }
      email_addresses {
        id
        email_address
      }
    }
  }
`;

const People = () => (
  <Query query={PeopleQuery}>
    {({ loading, error, data: { persons } }) => {
      if (loading) return <Loading />;
      if (error) return `Error!: ${error}`;
      return (
        <React.Fragment>
          <PeopleTable persons={persons} />
        </React.Fragment>
      );
    }}
  </Query>
);

export default People;
