import bugsnag from 'bugsnag-js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  Form,
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
} from 'reactstrap';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Footer from '../../components/Footer/Footer';

import { TOKEN_NAME } from '../../constants';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { redirectToReferrer: false };
  }

  handleSubmit = event => {
    event.preventDefault();
    if (!this.email.value) {
      return;
    }

    if (this.email.value && this.password.value) {
      this.login();
    }
  };

  login = () => {
    const email = this.email.value;
    const password = this.password.value;
    this.props
      .mutate({ variables: { email, password } })
      .then(result => {
        if (
          result &&
          result.data &&
          result.data.login &&
          result.data.login.token
        ) {
          localStorage.setItem(TOKEN_NAME, result.data.login.token);
          bugsnag.user = {
            email,
          };
          this.props.client.resetStore().then(res => {
            this.setState({ redirectToReferrer: true });
          });
        }
      })
      .catch(error => console.error(error));
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <div className="app">
        <div className="app-body flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <CardGroup className="mb-4">
                  <Card className="p-4">
                    <CardBody>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <Form onSubmit={this.handleSubmit}>
                        <InputGroup className="mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="icon-user" />
                            </span>
                          </div>
                          <Input
                            required
                            type="email"
                            innerRef={input => (this.email = input)}
                            placeholder="Email"
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="icon-lock" />
                            </span>
                          </div>
                          <Input
                            required
                            type="password"
                            innerRef={input => (this.password = input)}
                            placeholder="Password"
                          />
                        </InputGroup>
                        <Row>
                          <Col xs="6">
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                            >
                              Login
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            {/* <Button
                            onClick={() =>
                              this.props.history.push('/forgot-password')
                            }
                            color="link"
                            className="px-0"
                          >
                            Forgot password?
                          </Button> */}
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ width: '100%' }}>
          <Footer marginLeft={0} />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  mutate: PropTypes.func.isRequired,
};

const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export default withApollo(graphql(loginMutation)(Login));
