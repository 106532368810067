import React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../components/Loading';
import PendingDonationsTable from '../../components/PendingDonationsTable';

const PendingGifts = gql`
  query pendingGifts {
    pendingGifts {
      id
      metadataDonationSplitsId
      trial_end
      person {
        recognition_name
        id
      }
      pendingSplits {
        fund_id
        name
        amount
        memo
        fund {
          id
          name
        }
      }
    }
  }
`;

const PendingDonations = () => (
  <Query query={PendingGifts}>
    {({ loading, error, data: { pendingGifts } }) => {
      if (loading) return <Loading />;
      if (error) return `Error!: ${error}`;
      return (
        <React.Fragment>
          <PendingDonationsTable pendingGifts={pendingGifts} />
        </React.Fragment>
      );
    }}
  </Query>
);

export default PendingDonations;
