import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { Button } from 'reactstrap';

import '../styles/filterBar.css';

const ulStyle = {
  listStyle: 'none',
  float: 'left',
};

class FilterBar extends React.Component {
  constructor(props) {
    super(props);

    const {
      location: { pathname, search },
    } = props.history;

    const path = pathname;
    const params = queryString.parse(search);
    const { range } = params;
    let newRange;
    if (range) {
      newRange = range;
      if (range === 'today' || range === 'yesterday' || range === 'all_dates') {
        newRange = 'month';
      }
    }

    this.state = {
      dateRangeVisible: !!params.rangeFrom,
      focusedInput: null,
      params,
      newRange,
      path,
      rangeFrom: params.rangeFrom ? moment(new Date(params.rangeFrom)) : null,
      rangeTo: params.rangeTo ? moment(new Date(params.rangeTo)) : null,
    };
  }

  showDateRange() {
    this.setState({
      dateRangeVisible: !this.state.dateRangeVisible,
      focusedInput: 'startDate',
    });
  }

  isThisActive(key, value) {
    const {
      location: { search },
    } = this.props.history;

    const params = queryString.parse(search);
    const { rangeFrom, rangeTo, range, timeFrame } = params;

    if (rangeFrom) {
      return false;
    }
    if (key === 'range') {
      if (range) {
        if (range === value) {
          return true;
        }
      } else if (value === 'month') {
        return true;
      }
    }
    if (key === 'timeframe') {
      if (timeFrame) {
        if (timeFrame === value) {
          return true;
        }
      } else if (value === 'this') {
        if (
          range === 'today' ||
          range === 'yesterday' ||
          range === 'all_dates'
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
    return false;
  }

  changeDateRange(e) {
    e.preventDefault();
    const { rangeFrom, rangeTo } = this.state;
    const fromFormated = moment(rangeFrom).format('MMM+DD,+YYYY');
    const toFormated = moment(rangeTo).format('MMM+DD,+YYYY');
    this.props.history.push(
      `${this.state.path}?rangeFrom=${fromFormated}&rangeTo=${toFormated}`,
    );
  }

  render() {
    return (
      <div className="col-sm-4">
        <div className="callout callout-info">
          <small className="text-muted">Filter</small>
          <br />
          <div style={{ boxSizing: 'border-box' }}>
            <div className="pillbox_nav labeled" style={{ display: 'flex' }}>
              <div className="navgroup" style={{ flexDirection: 'row' }}>
                <ul style={ulStyle}>
                  <li id="navigation_this">
                    <NavLink
                      to={`${this.state.path}?range=${this.state.newRange ||
                        'month'}&timeFrame=this`}
                      isActive={() => this.isThisActive('timeframe', 'this')}
                    >
                      This
                    </NavLink>
                  </li>
                  <li id="navigation_last">
                    <NavLink
                      to={`${this.state.path}?range=${this.state.newRange ||
                        'month'}&timeFrame=last`}
                      isActive={() => this.isThisActive('timeframe', 'last')}
                    >
                      Last
                    </NavLink>
                  </li>
                </ul>
                <ul style={ulStyle}>
                  <li className="selected" id="navigation_week">
                    <NavLink
                      to={`${this.state.path}?range=week&timeFrame=${this.state
                        .params.timeFrame || 'this'}`}
                      isActive={() => this.isThisActive('range', 'week')}
                    >
                      Week
                    </NavLink>
                  </li>
                  <li id="navigation_month">
                    <NavLink
                      to={`${this.state.path}?range=month&timeFrame=${this.state
                        .params.timeFrame || 'this'}`}
                      isActive={() => this.isThisActive('range', 'month')}
                    >
                      Month
                    </NavLink>
                  </li>
                  <li id="navigation_quarter">
                    <NavLink
                      to={`${this.state.path}?range=quarter&timeFrame=${this
                        .state.params.timeFrame || 'this'}`}
                      isActive={() => this.isThisActive('range', 'quarter')}
                    >
                      Quarter
                    </NavLink>
                  </li>
                  <li id="navigation_year">
                    <NavLink
                      to={`${this.state.path}?range=year&timeFrame=${this.state
                        .params.timeFrame || 'this'}`}
                      isActive={() => this.isThisActive('range', 'year')}
                    >
                      Year
                    </NavLink>
                  </li>
                </ul>
                <ul style={ulStyle}>
                  <li id="navigation_today">
                    <NavLink
                      to={`${this.state.path}?range=today`}
                      isActive={() => this.isThisActive('range', 'today')}
                    >
                      Today
                    </NavLink>
                  </li>
                  <li id="navigation_yesterday">
                    <NavLink
                      to={`${this.state.path}?range=yesterday`}
                      isActive={() => this.isThisActive('range', 'yesterday')}
                    >
                      Yesterday
                    </NavLink>
                  </li>
                  <li id="navigation_all_dates">
                    <NavLink
                      to={`${this.state.path}?range=all_dates`}
                      isActive={() => this.isThisActive('range', 'all_dates')}
                    >
                      All Dates
                    </NavLink>
                  </li>
                  <li id="navigation_date_range">
                    <Button
                      color="link"
                      tabIndex="0"
                      onClick={() => this.showDateRange()}
                      style={{ cursor: 'pointer', padding: 0 }}
                      id="toggle_date_range"
                      active={!!this.state.rangeFrom}
                    >
                      Date Range
                    </Button>
                  </li>
                </ul>

                <div
                  id="date_range"
                  style={{
                    display: this.state.dateRangeVisible ? 'block' : 'none',
                  }}
                >
                  <form onSubmit={e => this.changeDateRange(e)}>
                    <DateRangePicker
                      small
                      isOutsideRange={day =>
                        isInclusivelyAfterDay(day, moment().add(1, 'days'))
                      }
                      startDate={this.state.rangeFrom}
                      startDateId="your_unique_start_date_id"
                      endDate={this.state.rangeTo}
                      endDateId="your_unique_end_date_id"
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({
                          rangeFrom: startDate,
                          rangeTo: endDate,
                        })
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput =>
                        this.setState({ focusedInput })
                      }
                    />
                    <Button outline color="primary" type="submit">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterBar;
