import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import queryString from 'query-string';

import 'react-table/react-table.css';
import '../styles/table.css';

import { defaultFilterMethod } from '../utils';

const columns = [
  {
    Header: 'Recognition Name',
    id: 'recognition_name',
    accessor: d =>
      d.donation &&
      d.donation.person &&
      d.donation.person.recognition_name && (
        <Link to={`/person/${d.donation.person.id}`}>
          {d.donation.person.recognition_name}
        </Link>
      ),
    filterMethod: (filter, row) =>
      defaultFilterMethod(filter, row._original.donation.person),
    sortMethod: (a, b, desc) => {
      a = a && a.props && a.props.children;
      b = b && b.props && b.props.children;
      // force null and undefined to the bottom
      a = a === null || a === undefined ? -Infinity : a;
      b = b === null || b === undefined ? -Infinity : b;
      // force any string values to lowercase
      a = typeof a === 'string' ? a.toLowerCase() : a;
      b = typeof b === 'string' ? b.toLowerCase() : b;
      // Return either 1 or -1 to indicate a sort priority
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
      return 0;
    },
    maxWidth: 300,
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: d => `$${(d.amount_in_cents / 100).toFixed(2)}`,
    maxWidth: 200,
    sortMethod: (a, b) => {
      if (Number(a.slice(1)) === Number(b.slice(1))) {
        return 0;
      }
      return Number(a.slice(1)) > Number(b.slice(1)) ? 1 : -1;
    },
  },
  {
    Header: 'Fund Name',
    id: 'name',
    accessor: d => d && d.fund && d.fund.name,
    maxWidth: 200,
  },
  {
    Header: 'Note',
    id: 'note',
    accessor: d => d.memo,
  },
  {
    Header: 'Type',
    id: 'donation_type_name',
    accessor: d => `${d.donation && d.donation.donation_type_name}`,
  },
  {
    Header: 'Received On',
    accessor: 'received_on',
    maxWidth: 110,
  },
];

const DonationsTable = ({ splits, history }) => {
  const params = queryString.parse(history.location.search);
  const { name } = params;

  return (
    <ReactTable
      data={splits}
      filterable
      defaultFilterMethod={defaultFilterMethod}
      columns={columns}
      defaultPageSize={10}
      className="-striped -highlight"
      defaultSorted={[
        {
          id: 'received_on',
          desc: true,
        },
      ]}
      defaultFiltered={
        name
          ? [
              {
                id: 'name',
                value: name,
              },
            ]
          : []
      }
    />
  );
};

DonationsTable.propTypes = {
  splits: PropTypes.arrayOf(
    PropTypes.shape({
      received_on: PropTypes.string,
      memo: PropTypes.string,
      fund: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      donation: PropTypes.shape({
        person: PropTypes.shape({
          recognition_name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default DonationsTable;
