import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { TOKEN_NAME } from '../constants';

class CheckToken extends Component {
  componentDidMount() {
    const token = localStorage.getItem(TOKEN_NAME);
    if (!token) {
      this.props.history.push('/login');
    }
    this.props
      .mutate({ variables: { token } })
      .then((result) => {
        if (result && result.data && result.data.refreshToken) {
          localStorage.setItem(TOKEN_NAME, result.data.refreshToken);
          this.props.history.push('/dashboard');
        }
      })
      .catch(() => {
        localStorage.removeItem(TOKEN_NAME);
        this.props.history.push('/login');
      });
  }

  render() {
    return <div>Loading</div>;
  }
}

const refreshTokenMutation = gql`
  mutation($token: String) {
    refreshToken(token: $token)
  }
`;

export default graphql(refreshTokenMutation)(CheckToken);
