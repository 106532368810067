import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import {
  Button,
  CardHeader,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Form,
  FormText
} from 'reactstrap';
import { toast } from 'react-toastify';
import UsersList from '../../components/UsersList';

const QEURY_FOR_USER_SETUP_PARTS = gql`
  query orgsAndRoles {
    orgs {
      id
      name
    }
    __type(name: "Role") {
      name
      enumValues {
        name
      }
    }
  }
`;

const SIGNUP_USER = gql`
  mutation signup(
    $email: String!
    $password: String!
    $name: String!
    $org: OrgCreateOneWithoutUsersInput!
    $role: Role!
  ) {
    signup(
      data: {
        email: $email
        password: $password
        name: $name
        org: $org
        role: $role
      }
    ) {
      user {
        id
      }
    }
  }
`;

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      orgId: '',
      role: '',
    };
  }

  onChangeInput(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }

  render() {
    return (
      <Query query={QEURY_FOR_USER_SETUP_PARTS}>
        {({ loading, error, data: { orgs, __type } }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error :(</p>;

          return (
            <Mutation mutation={SIGNUP_USER}>
              {(signup, { mutationLoading, mutationError }) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    signup({
                      variables: {
                        name: this.state.name,
                        email: this.state.email,
                        password: this.state.password,
                        org: { connect: { id: this.state.orgId } },
                        role: this.state.role,
                      },
                    }).then(() => {
                      this.setState({
                        name: '',
                        email: '',
                        password: '',
                        orgId: '',
                        role: '',
                      });
                      toast.success('User created successfully.');
                    });
                  }}
                >
                  <FormGroup>
                    <Label htmlFor="nf-email">Name</Label>
                    <Input
                      placeholder="Enter Their Name.."
                      value={this.state.name}
                      onChange={event => this.onChangeInput(event, 'name')}
                    />
                    <FormText className="help-block">
                      Please enter their name
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nf-email">Email</Label>
                    <Input
                      type="email"
                      placeholder="Enter Their Email.."
                      value={this.state.email}
                      onChange={event => this.onChangeInput(event, 'email')}
                    />
                    <FormText className="help-block">
                      Please enter their email
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nf-password">Password</Label>
                    <Input
                      type="password"
                      placeholder="Enter Their Password.."
                      value={this.state.password}
                      onChange={event => this.onChangeInput(event, 'password')}
                    />
                    <FormText className="help-block">
                      Please enter their password
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>Role</Label>
                    <Input
                      type="select"
                      name="select"
                      onChange={event => this.onChangeInput(event, 'role')}
                      value={this.state.role}
                      required
                    >
                      <option value="" disabled>
                        Select one...
                      </option>
                      {__type.enumValues.map(role => (
                        <option key={role.name} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Member of</Label>
                    <Input
                      type="select"
                      name="select"
                      onChange={event => this.onChangeInput(event, 'orgId')}
                      value={this.state.orgId}
                      required
                    >
                      <option value="" disabled>
                        Select one...
                      </option>
                      {orgs.map(org => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <Button type="submit" size="sm" color="primary">
                    <i className="fa fa-dot-circle-o" /> Submit
                  </Button>
                  {mutationLoading && <p>Loading...</p>}
                  {mutationError &&
                    toast.error('User not created.') && (
                    <p>Error :( Please try again</p>
                  )}
                </Form>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

const Admin = () => (
  <Row>
    <Col>
      <Card>
        <CardHeader>
          <strong>New User</strong>
          <small> Form</small>
        </CardHeader>
        <CardBody>
          <NewUser />
        </CardBody>
      </Card>
    </Col>
    <Col>
      <Card>
        <CardHeader>
          <strong>Users</strong>
        </CardHeader>
        <CardBody>
          <UsersList />
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default Admin;
