import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Row } from 'reactstrap';
import flatten from 'lodash.flatten';

import '../../styles/person.scss';
import DonationsTable from '../../components/DonationsTable';

const PERSON = gql`
  query person($id: Int!) {
    person(id: $id) {
      id
      donations {
        id
        amount_in_cents
        memo
        splits {
          memo
          received_on
          id
          amount_in_cents
          fund {
            id
            name
          }
          donation {
            id
            amount_in_cents
            donation_type_name
            person {
              recognition_name
              id
            }
          }
        }
      }
      recognition_name
      addresses {
        id
        city
        state
        street_address
        postal_code
        do_not_mail
      }
      email_addresses {
        id
        email_address
        do_not_email
      }
      phone_numbers {
        id
        location
        phone_number
        do_not_call
      }
    }
  }
`;

const Person = (props) => {
  const { id } = props.match.params;
  return (
    <Query query={PERSON} variables={{ id: id && Number(id) }}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) {
          console.error(error);
          return <p>Error :(</p>;
        }
        if (!data) return <p>Could not find that person</p>;
        const person = data && data.person;
        console.log(data);
        const splits = person.donations && person.donations.length
          ? flatten(person.donations.map(donation => donation.splits))
          : null;
        return (
          <React.Fragment>
            <Row>
              <dl className="dl-horizontal">
                <dt>Recognition Name</dt>
                <dd>{person.recognition_name}</dd>
                {person.phone_numbers && person.phone_numbers.length
                  ? person.phone_numbers.map(phoneNumber => (
                    <React.Fragment key={phoneNumber.id}>
                      <dt>Phone Number</dt>
                      <dd>
                        <a href={`tel:${phoneNumber.phone_number}`}>
                          {phoneNumber.phone_number}
                        </a>
                      </dd>
                    </React.Fragment>
                  ))
                  : null}
                {person.email_addresses && person.email_addresses.length
                  ? person.email_addresses.map(emailAddress => (
                    <React.Fragment key={emailAddress.id}>
                      <dt>Email</dt>
                      <dd>
                        <a href={`mailto:${emailAddress.email_address}`}>
                          {emailAddress.email_address}
                        </a>
                      </dd>
                    </React.Fragment>
                  ))
                  : null}
                {person.addresses && person.addresses.length
                  ? person.addresses.map(address => (
                    <React.Fragment key={address.id}>
                      <dt>Address</dt>
                      <dd>
                        {`${address.street_address} ${address.city}, ${
                          address.state
                        } ${address.postal_code}`}
                      </dd>
                    </React.Fragment>
                  ))
                  : null}
              </dl>
            </Row>
            {person.donations && person.donations.length ? (
              <DonationsTable splits={splits} history={props.history} />
            ) : null}
            <Row />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default Person;
