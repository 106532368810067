import bugsnag from '@bugsnag/js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { withApollo, graphql } from 'react-apollo';
import { Container } from 'reactstrap';
import USER_QUERY from '../../components/Me';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import Loading from '../../components/Loading';

import Dashboard from '../../views/Dashboard/Dashbaord';
import People from '../../views/People/People';
import Person from '../../views/People/Person';
import NewPeople from '../../views/People/NewPeople';
import Donations from '../../views/Donations/Donations';
import PendingDonations from '../../views/Donations/PendingDonations';
// import { Wires } from '../../views/Wires/Wires';
import Funds from '../../views/Funds/Funds';
import Admin from '../../views/Admin/Admin';
import { hasAtLeastRole } from '../../utils';

class Full extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Error(error);
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }
    if (this.props.data.me) {
      return (
        <div className="app">
          <Header
            history={this.props.history}
            resetStore={this.props.client && this.props.client.resetStore}
            me={this.props.data.me}
          />
          <div className="app-body">
            <Sidebar {...this.props} />
            <main className="main">
              <Breadcrumb />
              <Container fluid>
                <Switch>
                  <Route
                    exact
                    path="/dashboard"
                    name="Dashboard"
                    component={Dashboard}
                  />
                  <Route
                    exact
                    path="/person/:id"
                    name="Person"
                    component={Person}
                  />
                  <Route
                    exact
                    path="/people"
                    name="People"
                    component={People}
                  />
                  <Route
                    exact
                    path="/people/new"
                    name="NewPeople"
                    component={NewPeople}
                  />
                  <Route
                    exact
                    path="/donations"
                    name="Donations"
                    component={Donations}
                  />
                  <Route
                    exact
                    path="/donations/pending"
                    name="Pending Donations"
                    component={PendingDonations}
                  />
                  {/* {hasAtLeastRole(this.props.data.me.role, 'MANAGER') ? (
                    <Route exact path="/wires" name="Wires" component={Wires} />
                  ) : null} */}
                  <Route exact path="/funds" name="Funds" component={Funds} />
                  {hasAtLeastRole(this.props.data.me.role, 'SUPER_ADMIN') ? (
                    <Route exact path="/admin" name="Admin" component={Admin} />
                  ) : null}
                  <Redirect from="/" to="dashboard" />
                </Switch>
              </Container>
            </main>
          </div>
          <Footer />
        </div>
      );
    }
    return <Redirect to="/login" />;
  }
}

Full.propTypes = {
  data: PropTypes.shape({
    me: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
    }),
    loading: PropTypes.bool,
  }).isRequired,
};

export default withApollo(
  graphql(USER_QUERY, { options: { fetchPolicy: 'cache-and-network' } })(Full)
);
