import React from 'react';
import moment from 'moment';

const Footer = ({ marginLeft = null }) => {
  const year = moment().year();
  const style = {};
  if (marginLeft !== null) {
    style.marginLeft = marginLeft;
  }
  return (
    <footer className="app-footer" style={style}>
      <span>
        <a href="https://github.com/c316/coffee">Coffee</a> - Deliciously easy
        donation and donor management. &copy; {year} Trash Mountain Project.
      </span>
      <span className="ml-auto">ver {process.env.npm_package_version}</span>
    </footer>
  );
};

export default Footer;
