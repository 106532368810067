import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSent: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Error(error);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.email.value) {
      return;
    }
    this.forgotPassword();
    // TODO: handle no value error (the form already has these fields as required, so it would probably be a problem with
    // this[inputName].value)
  };

  forgotPassword = () => {
    const email = this.email.value;
    this.props
      .mutate({ variables: { email } })
      .then((result) => {
        this.setState({ showSent: true });
      })
      .catch((error) => console.error(error));
  };

  render() {
    const containerStyle = {
      zIndex: 1999,
    };

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card hidden={this.state.showSent ? false : true}>
                <h1>Email sent, please check your inbox!</h1>
              </Card>
              <CardGroup className="mb-4">
                <Card className="p-4">
                  <CardBody>
                    <h1>Forgot Password</h1>
                    <p className="text-muted">Send a password reset email</p>
                    <Form onSubmit={this.handleSubmit}>
                      <InputGroup className="mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-user" />
                          </span>
                        </div>
                        <Input
                          required
                          type="email"
                          innerRef={(input) => (this.email = input)}
                          placeholder="Email"
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button
                            type="submit"
                            color="primary"
                            className="px-4"
                          >
                            Send Reset Email
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button
                            tag={Link}
                            to="/login"
                            color="link"
                            className="px-0"
                          >
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  mutate: PropTypes.func.isRequired,
};

const forgotPasswordMutation = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export default withApollo(graphql(forgotPasswordMutation)(ForgotPassword));
