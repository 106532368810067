import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { defaultFilterMethod } from '../utils';
import '../styles/table.css';
import PersonsDownload from './PersonsDownload';

const columns = [
  {
    Header: 'Recognition Name',
    id: 'recognition_name',
    accessor: d => <Link to={`/person/${d.id}`}>{d.recognition_name}</Link>,
    filterMethod: (filter, row) => defaultFilterMethod(filter, row._original),
    sortMethod: (a, b) => {
      a = a.props.children;
      b = b.props.children;
      // force null and undefined to the bottom
      a = a === null || a === undefined ? -Infinity : a;
      b = b === null || b === undefined ? -Infinity : b;
      // force any string values to lowercase
      a = typeof a === 'string' ? a.toLowerCase() : a;
      b = typeof b === 'string' ? b.toLowerCase() : b;
      // Return either 1 or -1 to indicate a sort priority
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
      return 0;
    },
    maxWidth: 200,
  },
  {
    Header: 'Address',
    id: 'address',
    accessor: d =>
      d.addresses &&
      d.addresses[0] &&
      `${d.addresses[0].street_address} ${d.addresses[0].city}, ${
        d.addresses[0].state
      } ${d.addresses[0].postal_code}`,
  },
  {
    Header: 'Emails',
    id: 'emails',
    accessor: d => d.email_addresses.map(email => `${email.email_address} `),
    maxWidth: 250,
  },
  {
    Header: 'Phone Numbers',
    id: 'phones',
    accessor: d => d.phone_numbers.map(phone => `${phone.phone_number}`),
    maxWidth: 150,
  },
  {
    Header: '1st Donation Date',
    id: 'first_donation_date',
    accessor: d => d.first_donation_date,
    maxWidth: 135,
  },
];

const PeopleTable = ({ persons }) => (
  <div>
    <ReactTable
      data={persons}
      filterable
      defaultFilterMethod={defaultFilterMethod}
      columns={columns}
      defaultPageSize={10}
      className="-striped -highlight"
      defaultSorted={[
        {
          id: 'first_donation_date',
          desc: true,
        },
      ]}
    />
    <br />
    <PersonsDownload persons={persons} />
  </div>
);

export default PeopleTable;
