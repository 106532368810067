import React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';
import USER_QUERY from './Me';
import { toast } from 'react-toastify';

const roleTypeToReadableName = role => {
  let name = '';
  switch (role) {
    case 'SUPER_ADMIN':
      name = 'Super Admin';
      break;
    case 'ADMIN':
      name = 'Admin';
      break;
    case 'MANAGER':
      name = 'Manager';
      break;
    case 'USER':
      name = 'User';
      break;
    default:
      name = '';
  }
  return name;
};

const QEURY_FOR_USERS = gql`
  query users {
    users {
      id
      name
      email
      role
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const UsersList = () => (
  <Mutation mutation={DELETE_USER}>
    {(deleteUser, { mutationLoading, mutationError }) => (
      <Query query={USER_QUERY}>
        {({ loading: meLoading, error: meError, data: { me } }) => {
          if (meLoading) return <p>Loading...</p>;
          if (meError) return <p>Error :\</p>;
          return (
            <Query query={QEURY_FOR_USERS}>
              {({ loading, error, data: { users }, refetch }) => {
                if (loading) return <p>Loading...</p>;
                if (error) return <p>Error :\</p>;
                return (
                  <ul>
                    {users &&
                      users.map(user => (
                        <li key={user.id}>
                          {`${user.name} - ${roleTypeToReadableName(
                            user.role
                          )}`}{' '}
                          {me.id === user.id ? null : (
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => {
                                deleteUser({
                                  variables: {
                                    id: user.id,
                                  },
                                }).then(() => {
                                  refetch();
                                  toast.success('User deleted.');
                                });
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </li>
                      ))}
                  </ul>
                );
              }}
            </Query>
          );
        }}
      </Query>
    )}
  </Mutation>
);

export default UsersList;
