import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import '../styles/table.css';
import { defaultFilterMethod } from '../utils';

const columns = [
  {
    Header: 'Recognition Name',
    id: 'recognition_name',
    accessor: d =>
      d &&
      d.person &&
      d.person.id && (
        <Link to={`/person/${d.person.id}`}>{d.person.recognition_name}</Link>
      ),
    maxWidth: 300,
    filterMethod: (filter, row) =>
      defaultFilterMethod(filter, row._original.person),
  },
  {
    Header: 'Amounts',
    id: 'amount',
    accessor: d =>
      d.pendingSplits.map(split => `$${(split.amount / 100).toFixed(2)} `),
    maxWidth: 200,
    sortMethod: (prev, current) => {
      const prevTotal = prev.reduce((a, b) => {
        const total = a + Number(b.slice(1));
        return total;
      }, 0);
      const currentTotal = current.reduce((a, b) => {
        const total = a + Number(b.slice(1));
        return total;
      }, 0);

      if (prevTotal === currentTotal) {
        return 0;
      }

      return prevTotal < currentTotal ? 1 : -1;
    },
  },
  {
    Header: 'Fund Names',
    id: 'funds',
    accessor: d => d.pendingSplits.map(split => `${split.fund.name} `),
    maxWidth: 200,
  },
  {
    Header: 'Notes',
    id: 'notes',
    accessor: d =>
      d.pendingSplits.map(split => (split.memo && `${split.memo} `) || ''),
  },
  {
    Header: 'Start Date',
    id: 'start',
    accessor: d =>
      d.trial_end &&
      moment
        .unix(d.trial_end)
        .utc()
        .format('YYYY-MM-DD'),
    maxWidth: 110,
  },
];

const PendingDonationsTable = ({ pendingGifts }) => {
  const filteredPendingGifts = pendingGifts.filter((gift) => {
    if (gift.pendingSplits) return gift;
    return null;
  });
  return (
    <div>
      <ReactTable
        data={filteredPendingGifts}
        filterable
        defaultFilterMethod={defaultFilterMethod}
        columns={columns}
        defaultPageSize={10}
        className="-striped -highlight"
        defaultSorted={[
          {
            id: 'start',
            desc: false,
          },
        ]}
      />
      <br />
    </div>
  );
};

PendingDonationsTable.propTypes = {
  pendingGifts: PropTypes.arrayOf(PropTypes.shape({
    pendingSplits: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      trial_end: PropTypes.string,
      memo: PropTypes.string,
      fund: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })),
    person: PropTypes.shape({
      recognition_name: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

export default PendingDonationsTable;
