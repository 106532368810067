import bugsnag from 'bugsnag-js';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import me from '../../components/Me';
import {
  Form,
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';

import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { TOKEN_NAME } from '../../constants';

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      token
    }
  }
`;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
      showChanged: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Error(error);
  }

  validatePassword = () => {
    if (
      this.state.password &&
      this.state.passwordConfirm &&
      this.state.password !== this.state.passwordConfirm
    ) {
      this.passwordConfirm.setCustomValidity("Passwords Don't Match");
    } else {
      this.passwordConfirm.setCustomValidity('');
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.validatePassword();
  };

  render() {
    return (
      <Query query={me}>
        {({ loading, error, data: { me } }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error :(</p>;
          return (
            <Mutation mutation={CHANGE_PASSWORD}>
              {(changePassword, { loading, error }) => (
                <div className="app flex-row align-items-center">
                  <Container>
                    <Row className="justify-content-center">
                      <Col md="8">
                        <Card hidden={this.state.showChanged ? false : true}>
                          <h1>Email sent, please check your inbox!</h1>
                        </Card>
                        <CardGroup className="mb-4">
                          <Card className="p-4">
                            <CardBody>
                              <h1>Change Your Password</h1>
                              <br />
                              <Form
                                onSubmit={event => {
                                  event.preventDefault();
                                  if (!me.email) {
                                    return;
                                  }
                                  const { email } = me;
                                  const {
                                    oldPassword,
                                    password,
                                    passwordConfirm,
                                  } = this.state;
                                  if (
                                    oldPassword &&
                                    password === passwordConfirm
                                  ) {
                                    changePassword({
                                      variables: {
                                        email,
                                        oldPassword,
                                        newPassword: password,
                                      },
                                    })
                                      .then(result => {
                                        if (
                                          result &&
                                          result.data &&
                                          result.data.changePassword &&
                                          result.data.changePassword.token
                                        ) {
                                          localStorage.setItem(
                                            TOKEN_NAME,
                                            result.data.changePassword.token
                                          );
                                          bugsnag.user = {
                                            email,
                                          };
                                          toast.success(
                                            'Password changed successfully.',
                                            {
                                              onClose: () => {
                                                this.props.history.push(
                                                  '/dashboard'
                                                );
                                              },
                                            }
                                          );
                                        }
                                      })
                                      .catch(error => {
                                        toast.error('That did not work');
                                      });
                                  }
                                }}
                              >
                                <FormGroup row>
                                  <Label for="oldPassword" sm={3}>
                                    Current Password
                                  </Label>
                                  <Col sm={8}>
                                    <Input
                                      type="password"
                                      name="oldPassword"
                                      onChange={e => this.onChange(e)}
                                      placeholder="Your current password"
                                      required
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Label for="password" sm={3}>
                                    New Password
                                  </Label>
                                  <Col sm={8}>
                                    <Input
                                      type="password"
                                      name="password"
                                      placeholder="New Password"
                                      required
                                      onChange={e => this.onChange(e)}
                                      onKeyUp={e => this.onChange(e)}
                                      innerRef={input =>
                                        (this.password = input)
                                      }
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Label for="passwordConfirm" sm={3}>
                                    Confirm Password
                                  </Label>
                                  <Col sm={8}>
                                    <Input
                                      type="password"
                                      name="passwordConfirm"
                                      placeholder="Confirm Password"
                                      required
                                      onChange={e => this.onChange(e)}
                                      onKeyUp={e => this.onChange(e)}
                                      innerRef={input =>
                                        (this.passwordConfirm = input)
                                      }
                                    />
                                  </Col>
                                </FormGroup>
                                <FormGroup check row>
                                  <Col sm={{ size: 10 }}>
                                    <Button>Submit</Button>
                                  </Col>
                                </FormGroup>
                                {loading && <p>Loading...</p>}
                                {error &&
                                  error.graphQLErrors.map(({ message }, i) => (
                                    <p key={i}>{message}</p>
                                  ))}
                              </Form>
                            </CardBody>
                          </Card>
                        </CardGroup>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

const changePasswordMutation = gql`
  mutation changePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      token
    }
  }
`;

export default ChangePassword;
