import React from 'react';
import queryString from 'query-string';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../components/Loading';
import DonationsTable from '../../components/DonationsTable';
import FilterBar from '../../components/FilterBar';
import DownloadToExcel from '../../components/DownloadToExcel';

const SplitsQuery = gql`
  query SplitsQuery(
    $range: Range
    $timeFrame: TimeFrame
    $rangeFrom: DateTime
    $rangeTo: DateTime
  ) {
    splits(
      range: $range
      timeFrame: $timeFrame
      rangeFrom: $rangeFrom
      rangeTo: $rangeTo
    ) {
      id
      received_on
      amount_in_cents
      memo
      donation {
        donation_type_id
        donation_type_name
        person {
          recognition_name
          id
          email_addresses {
            email_address
          }
          addresses {
            state
            street_address
            postal_code
            city
          }
          phone_numbers {
            phone_number
          }
        }
        id
        amount_in_cents
      }
      fund {
        id
        name
      }
    }
  }
`;

const Donations = (props) => {
  const { match, history, location } = props;
  const params = queryString.parse(location.search);

  return (
    <Query
      query={SplitsQuery}
      variables={{
        range: params.range && params.range.toUpperCase(),
        timeFrame: params.timeFrame && params.timeFrame.toUpperCase(),
        rangeFrom: params.rangeFrom,
        rangeTo: params.rangeTo,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return `Error!: ${error}`;
        const { splits } = data;
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row">
                          <FilterBar
                            location={match}
                            history={history}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DonationsTable splits={splits} history={history} />
            <DownloadToExcel splits={splits} />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default Donations;
