import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Nav,
  NavbarToggler,
  NavbarBrand,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap';
import bugsnag from 'bugsnag-js';
import { TOKEN_NAME } from '../../constants';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  render() {
    const logout = () => {
      localStorage.removeItem(TOKEN_NAME);
      bugsnag.user = {};
      this.props.resetStore();
      this.props.history.push('/login');
    };
    const orgName = this.props.me && this.props.me.org && this.props.me.org.name;
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <NavbarBrand tag={Link} to="/" />
        <NavbarToggler
          className="d-md-down-none mr-auto"
          onClick={this.sidebarToggle}
        >
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        {orgName ? (
          <NavbarToggler className="d-md-down-none mr-auto">{`${orgName}`}</NavbarToggler>
        ) : null}
        <Nav className="ml-auto" navbar>
          <Dropdown
            nav
            direction="left"
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
          >
            <DropdownToggle nav>
              <i className="fa fa-list-ul fa-lg" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header tag="div" className="text-center">
                <strong>Settings</strong>
              </DropdownItem>
              {this.props.me && this.props.me.role === 'SUPER_ADMIN' ? (
                <DropdownItem tag={Link} to="/admin">
                  <i className="fa fa-wrench" />
                  {' '}
Admin
                </DropdownItem>
              ) : null}
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/change-password">
                <i className="fa fa-lock" />
                {' '}
Change Password
              </DropdownItem>
              <DropdownItem onClick={() => logout()}>
                <i className="fa fa-lock" />
                {' '}
Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </header>
    );
  }
}

export default Header;
